<template>
  <div class="auth-wrapper auth-v1">
    <div class="py-5">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            Smart ADM
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          <p > Como instalar a autenticação em duas etapas com o Google Authenticator.</p>
        </b-card-title>

        <b-alert variant="danger" v-if="errors" show>
          <div class="alert-body" v-for="(error, index) in errors" :key="index">
            {{error[0]}}
          </div>
        </b-alert>

        <b-alert variant="danger" v-if="errorsNaoEncontrado" show>
          <div class="alert-body">
            {{ errorsNaoEncontrado }}
          </div>
        </b-alert>


        <template v-if="qrCode">
          <div style="text-align: left">
            <p><strong>Passo 01</strong> - No seu celular, abra a sua loja de aplicativos (Se for iPhone - App Store ou se for Android - Google Play Store), e busque por "Google Authenticator"</p>
            <p><strong>Passo 02</strong> - Clique em Instalar</p>
            <p><strong>Passo 03</strong> - Com a instalação feita, abra o aplicativo e clique em <strong>Primeiros passos</strong></p>
            <p><strong>Passo 04</strong> - Agora, clique em Ler código QR</p>
            <p><strong>Pra finalizar,</strong> retorne ao aplicativo no celular e aponte a câmera do celular para o monitor, para realizar a leitura do QR code e ativação da autenticação em duas etapas.</p>
            <p><strong>Clique no botão "Voltar para o Login"</strong></p>
          </div>

          <div class="text-center">
            <b-img :src="`https://chart.googleapis.com/chart?cht=qr&chs=300x300&chl=${qrCode}`"></b-img>
          </div>
        </template>

        <b-button variant="primary" block type="submit" :disabled="!!qrCode" @click="gerarCodigo">
          <b-spinner small v-if="spinner" />
          <span v-if="spinner">Aguarde...</span>
          <span v-else>Gerar Autenticação em duas etapas </span>
        </b-button>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> Voltar para login
          </b-link>
        </b-card-text>

      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>

import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BButton, BAlert, BSpinner, BCardImg, BImg,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import {mapState} from "vuex";

export default {

  name:'SubscribeGoogleAuthenticator',

  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BAlert,
    BSpinner,
    BCardImg,
    BImg,
  },
  data() {
    return {
      qrCode: '',
      errors: [],
      errorsNaoEncontrado: '',
      emailEnviado: false,
      spinner: false,
      proDisabled: false,

      // validation
      required,
      email,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user.data
    })
  },

  methods: {
    gerarCodigo() {
      this.errorsNaoEncontrado = ''

      if(this.user === undefined) {
        return this.errorsNaoEncontrado = 'Usuário não encontrado!'
      }

      this.submit = true;

      this.$http.post("adm/mfa/google-authenticator", { user_id: this.user.id })
        .then((res) => {

          this.qrCode =  res.data
          this.getErrors = false;
        })
        .catch((error) => {
          this.errorsNaoEncontrado = error.response.data.message
        })
        .finally(() => {
          this.submit = false;
        })
    },

    limparError(){
      this.errors = []
      this.errorsNaoEncontrado = ''
    },
  },


}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';

input {
  color: #3e60ab;
  font-size: 18px;
  font-family: "Arial Rounded MT Bold",serif;
  letter-spacing: 40px;
}
</style>
